:root {
  --font-size-1: 0.78125rem;
  --font-size-2: 0.9rem;
  --font-size-3: 1rem;
  --font-size-4: 1.2rem;
  --font-size-5: 1.5rem;
  --font-size-6: 1.75rem;
  --font-size-7: 2rem;
  --font-size-8: 2.5rem;
  --font-size-9: 3rem;
}
