@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.7s ease-out 1.5s forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate-fade-out {
  animation: fade-out 0.2s ease-out forwards;
}

@keyframes float-down {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(4px);
  }
}

.animate-float-down {
  animation: float-down 0.2s ease-out forwards;
}

@keyframes float-up {
  0% {
    transform: translateY(4px);
  }

  to {
    transform: translateY(0);
  }
}

.animate-float-up {
  animation: float-up 0.2s ease-out forwards;
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
    filter: blur(2px);
  }

  to {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

.animate-scale-in {
  animation: scale-in 0.2s ease-out forwards;
}

@keyframes site-description-heading {
  0% {
    opacity: 0;
    filter: blur(2px);
  }

  to {
    opacity: 1;
    filter: blur(0);
  }
}

.animate-site-description-heading {
  animation: site-description-heading 1s ease-in-out 0.5s forwards;
}

@keyframes site-description-paragraph {
  0% {
    opacity: 0;
    filter: blur(2px);
  }

  to {
    opacity: 1;
    filter: blur(0);
  }
}

.animate-site-description-paragraph {
  animation: site-description-paragraph 1s ease-in-out 0.8s forwards;
}
